import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Insights from './components/Insights'
import Spinner from 'react-bootstrap/Spinner'

export default function SearchPage() {

    const [searchResults, setSearchResults] = React.useState([])
    
    const [searching, setSearching] = React.useState(false)

    const [heatmapSrc, setHeatmapSrc] = React.useState(null)

    const [lengthData, setLengthData] = React.useState([])

    const [priceData, setPriceData] = React.useState([])


    const getImages = (fd) => {
        fetch("https://direct-api.wefindyourboat.com/search/image-search-with-insights", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(fd),
        })
          .then((res) => res.json())
          .then((data) => {
                console.log(data);
                var newSearchResults = []
                for (let i = 0; i < data.listings.length; i++) {
                    newSearchResults.push(data.listings[i])
                }
                
                setSearching(false)
                setSearchResults(newSearchResults)
                setHeatmapSrc(`data:image/jpeg;base64,${data.heatmap}`)
                setLengthData(data.boats_by_length)
                setPriceData(data.boats_by_price)

          })
      }


    function getBase64(imageFile) {
        var reader = new FileReader();
        reader.readAsDataURL(imageFile);
        reader.onload = function (e) {
            var img = document.createElement("img");
            img.src = e.target.result;
            img.onload = function (event) {
                // Dynamically create a canvas element
                var MAX_WIDTH = 500;
                var MAX_HEIGHT = 500;

                var width = img.width;
                var height = img.height;

                if (width > height) {
                if (width > MAX_WIDTH) {
                    height = height * (MAX_WIDTH / width);
                    width = MAX_WIDTH;
                }
                } else {
                    if (height > MAX_HEIGHT) {
                        width = width * (MAX_HEIGHT / height);
                        height = MAX_HEIGHT;
                    }
                }
                var canvas = document.createElement("canvas");
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                // Show resized image in preview element
                var dataurl = canvas.toDataURL(imageFile.type);
                var fd = {
                "image": dataurl.replace("data:", "").replace(/^.+,/, ""),
                "page": 1,
                }
                //ajaxRequest('https://seahorse-app-tsi7e.ondigitalocean.app/search', fd, searchSuccess, 'POST')
                getImages(fd)
            }
        }
    }


    function previewFile(file){
        if(file){
            var reader = new FileReader();

            reader.onload = function(){
                document.getElementById(`previewImg`).src = reader.result;
                document.getElementById(`previewImg`).hidden = false;
            }

            reader.readAsDataURL(file);
        }
    }   

    function runSearch() {
        var file = document.getElementById('file-input').files[0];
        previewFile(file)
        setSearching(true)
        getBase64(file);
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function getCurrencySymbol(currency) {
        const currencySymbolMap = {
            'USD': '$',
            'EUR': '€',
            'GBP': '£',
            'JPY': '¥',
            'AUD': '$',
            'CAD': '$',
            'CHF': 'CHF',
            'CNY': '¥',
            'HKD': '$',
            'NZD': '$',
        }
        if (currencySymbolMap[currency]) {
            return currencySymbolMap[currency];
        } else {
            return '$';
        }
    }

    function getDomain(url) {
        var domain;
        //find & remove protocol (http, ftp, etc.) and get domain
        if (url.indexOf("://") > -1) {
            domain = url.split('/')[2];
        }
        else {
            domain = url.split('/')[0];
        }
    
        //find & remove port number
        domain = domain.split(':')[0];
    
        return domain;
    }


  return (
    <div>
        <Container>
            <Row>
                <Col>
                <div style={{marginTop: '20vh', marginBottom: '20px'}}>
                    <div className="image-upload" style={{textAlign: 'center'}}>
                        <label htmlFor="file-input">
                            <Image id="previewImg" src="./upload.jpg" style={{width: '300px', borderRadius:'10px'}} />
                        </label>
                    
                        <input id="file-input" type="file" onChange={runSearch} style={{display: 'none'}} />
                    </div>
                </div>
                </Col>
            </Row>
            <Row>
                {
                    searching ?
                        <div style={{textAlign: 'center', marginTop: '20px'}}>
                            <Spinner className="styled-header" animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    :
                    null

                }

            </Row>
            <Row>
                { searchResults.length > 0 ?
                    <Insights heatmapSrc={heatmapSrc} lengthData={lengthData} priceData={priceData} />
                : 
                    null 
                }
            </Row>
            <Row>
                { searchResults.length > 0 ?
                    <div>
                        <h3 style={{color:'white', marginTop:'30px', marginBottom: '20px'}}>Search Results</h3>
                    {
                        searchResults.map((result, index) => {
                            return (
                                <div key={index} style={{marginBottom: '40px'}}>
                                    <Image src={result.image_url} style={{width:'100%'}}></Image> 
                                    <div style={{padding:'20px', backgroundColor:'white'}}>
                                        <h2>{result.title}</h2>
                                        { 
                                            result.price && result.currency ?
                                                <h2 className='styled-header'>{getCurrencySymbol(result.currency)}{formatNumber(parseInt(result.price))} {result.currency}</h2>
                                            :
                                                <h2 className='styled-header'>No Price Available</h2>
                                        }
                                        <div style={{backgroundColor: 'black', width: '100%', height:'1px'}}></div>
                                        <div style={{marginTop:'30px'}}>
                                            {
                                                result.country ?
                                                    <h4>{result.country}</h4>
                                                :
                                                    null
                                            }
                                            {
                                                result.length ?
                                                    <h4>Length: {result.length}m ({parseInt(result.length * 3.281)}ft)</h4>
                                                :
                                                    null
                                            }
                                            {
                                                result.year ?
                                                    <h4>Year: {result.year}</h4>
                                                :
                                                    null
                                            }
                                        </div>
                                        <div style={{marginTop: '30px'}}>
                                            <a style={{borderRadius: '5px', backgroundColor: 'gray', color: 'white', padding: '10px', textDecoration: 'none'}} href={result.url} target="_blank" rel="noopener noreferrer">
                                                View on {getDomain(result.url)}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                :
                    <h1>
                        Upload an image to get started!
                    </h1>
                }
            </Row>
        </Container>
    </div>
  )
}
