
import TopReportSection from './components/TopReportSection';
import ReportSection from './components/ReportSection';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState } from 'react';
import Button from 'react-bootstrap/Button'

function Insights(props) {

    const data = props.data
    console.log(data)

  const [selectedBoatType, setSelectedBoatType] = useState('Powerboat')
  const [selectedHullType, setSelectedHullType] = useState('Barge')
  const [selectedLength, setSelectedLength] = useState('10-15m')


  return (
    <div style={{paddingTop:'100px', backgroundColor:'rgb(100, 100, 100)', color: 'white'}}>
      <TopReportSection  boat_type_count={data['Boat Type']} power_hull_type_count={data['Powerboat Hull Type']} yacht_hull_type_count={data['Yacht Hull Type']} />
    
      <Container fluid style={{marginTop: '200px', paddingBottom: '200px'}}>
        <Row>
          <Col md={12} lg={1}></Col>
          <Col md={12} lg={3} style={{backgroundColor:'rgb(80, 80, 80', padding: '30px'}}>
            <div>
              <Row>
                <Col>
                  <Button style={{width: '100%'}} onClick={() => {setSelectedBoatType('Powerboat')}} className={selectedBoatType === 'Powerboat' ? 'btn-primary' : 'btn-secondary'}>Powerboat</Button>
                </Col>
                <Col>
                  <Button style={{width: '100%'}} onClick={() => {setSelectedBoatType('Yacht'); setSelectedHullType('Monohull')}} className={selectedBoatType === 'Yacht' ? 'btn-primary' : 'btn-secondary'}>Yacht</Button>
                </Col>
              </Row>
              <Row style={{marginTop:'30px'}}>
                { selectedBoatType === 'Powerboat' ?
                  <Col>
                    <Button style={{width: '100%'}} onClick={() => {setSelectedHullType('Barge')}} className={selectedHullType === 'Barge' ? 'btn-primary' : 'btn-secondary'}>Barge</Button>
                  </Col>
                  :
                  null
                }
                <Col>
                  <Button style={{width: '100%'}} onClick={() => {setSelectedHullType('Monohull')}} className={selectedHullType === 'Monohull' ? 'btn-primary' : 'btn-secondary'}>Monohull</Button>
                </Col>
                <Col>
                  <Button style={{width: '100%'}} onClick={() => {setSelectedHullType('Multihull')}} className={selectedHullType === 'Multihull' ? 'btn-primary' : 'btn-secondary'}>Multihull</Button>
                </Col>
              </Row>

              <Row style={{marginTop:'30px'}}>
                <Col>

                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('0-5m')}} className={selectedLength === '0-5m' ? 'btn-primary' : 'btn-secondary'}>0-5m</Button>

                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('5-10m')}} className={selectedLength === '5-10m' ? 'btn-primary' : 'btn-secondary'}>5-10m</Button>

                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('10-15m')}} className={selectedLength === '10-15m' ? 'btn-primary' : 'btn-secondary'}>10-15m</Button>

                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('15-20m')}} className={selectedLength === '15-20m' ? 'btn-primary' : 'btn-secondary'}>15-20m</Button>
                </Col>
                <Col>

                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('20-30m')}} className={selectedLength === '20-30m' ? 'btn-primary' : 'btn-secondary'}>20-30m</Button>


                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('30-50m')}} className={selectedLength === '30-50m' ? 'btn-primary' : 'btn-secondary'}>30-50m</Button>


                  <Button style={{width: '100%', marginTop: '10px'}} onClick={() => {setSelectedLength('50-100m')}} className={selectedLength === '50-100m' ? 'btn-primary' : 'btn-secondary'}>50-100m</Button>
                </Col>
              </Row>

            </div>
          </Col>
          <Col md={12} lg={8}>
            <ReportSection lengthDataMap={data[`${selectedBoatType} ${selectedHullType} ${selectedLength}`].lengths} priceDataMap={data[`${selectedBoatType} ${selectedHullType} ${selectedLength}`].prices} countryDataMap={data[`${selectedBoatType} ${selectedHullType} ${selectedLength}`].countries} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Insights;
