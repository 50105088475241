import React from 'react'
import { VictoryArea, VictoryChart, VictoryAxis } from 'victory';

export default function AreaGraph(props) {

    const dataArray = Object.entries(props.dataMap);
  
    const data = dataArray.map(([key, value]) => {
      return {
        x: parseInt(key),
        y: value
      }
    })


    const cleanData = [...data]


    
  
    return (
      <div>
        <VictoryChart>
          <VictoryAxis style={{
                axisLabel: {fontSize: 15, padding: 30, fill: "white"},
                tickLabels: {fontSize: 10, padding: 5, fill: "white"},
                labels: {fontSize: 5, padding: 5, fill: "white"},
            }}
            fixLabelOverlap={true}
            label={props.label}
            >
  
            </VictoryAxis>
            <VictoryArea
                interpolation="cardinal"
                style={{
                    data: { stroke: "rgb(1, 151, 221)", fill: "rgb(1, 151, 221)" },
                    parent: { border: "1px solid white"},
                    labels: {fill: 'white'},
                }}
                data={cleanData}
            />
        </VictoryChart>
      </div>
    );
}



