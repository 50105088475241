import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import AreaGraph from './AreaGraph'
import Map from './Map'


export default function ReportSection(props) {
  console.log(props.countryDataMap)
  return (
    <Container>
        <Row>
            <Col>
                <Map dataMap={props.countryDataMap} />
            </Col>
        </Row>
        <Row>
            <Col md={12} lg={6}>
                <AreaGraph dataMap={props.lengthDataMap} label={'Length (m)'} />
            </Col>
            <Col md={12} lg={6}>
                <AreaGraph dataMap={props.priceDataMap} label={'Price (USD)'} />
            </Col>
        </Row>
    </Container>
  )
}
