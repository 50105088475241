import React from 'react'
import { ComposableMap, Geographies, Geography, Sphere, Graticule } from "react-simple-maps";
import { scaleLinear } from "d3-scale";

export default function Map(props) {


    const geoUrl = "/features.json";

    const colorScale = scaleLinear()
        .domain([0.1, 1])
        .range(["#c5d8f0", "#076deb"]);

    
    function normalize_counts(data) {
        const counts = data.map((d) => d["count"]);
        console.log(counts)
        const max = Math.max(...counts);
        const normed_data = data.map((d) => {
            d["count"] = Math.sqrt(d["count"]) / Math.sqrt(max);
            return d;
        });
        return normed_data;
    }


    const data = normalize_counts(props.dataMap)

    

    


  return (
    <ComposableMap
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 147
      }}
    >
      <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
      {normalize_counts(data).length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const d = data.find((s) => s.id === geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d["count"]) : "#F5F4F6"}
                />
              );
            })
          }
        </Geographies>
      )}
    </ComposableMap>
  )
}
