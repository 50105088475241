import React from 'react';
import './App.css';
import Header from './components/Header';
import SearchPage from './pages/SearchPage/SearchPage';
import Insights from './pages/Insights/Insights';
import { useState } from 'react';
import data from './report.json'

function App() {

  const [cuurentPage, setCurrentPage] = useState('SearchPage');

  return (
    <div style={{backgroundColor: 'rgba(36, 39, 49)', minHeight:'100vh'}}>
      <Header setCurrentPage={setCurrentPage} />

      { cuurentPage === 'SearchPage' ?
        <SearchPage />
        :
        <Insights data={data} />
      }
    </div>
  );
}

export default App;
