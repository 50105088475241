import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {VictoryPie} from 'victory'



export default function TopReportSection(props) {
    
    const total = props.boat_type_count.Powerboat + props.boat_type_count.Yacht

    const boatTypeData = [
        {x: 'Powerboat', y: props.boat_type_count.Powerboat},
        {x: 'Yacht', y: props.boat_type_count.Yacht}
    ]
    
    const powerHullTypeData = [
        {x: 'Barge', y: props.power_hull_type_count.Barge},
        {x: 'Monohull', y: props.power_hull_type_count.Monohull},
        {x: 'Multihull', y: props.power_hull_type_count.Multihull},
    ]

    const yachtHullTypeData = [
        {x: 'Monohull', y: props.yacht_hull_type_count.Monohull},
        {x: 'Multihull', y: props.yacht_hull_type_count.Multihull},
    ]

    return (
        <Container>
            <Row>
                <div>
                    <h1 className='styled-header'>WeFindYourBoat Market Insights</h1>
                    <h2>Total boats for sale: {total.toLocaleString("en-US")}</h2>
                </div>
            </Row>
            <Row>
                <Col md={12} lg={4}>
                    <div style={{height: '300px', textAlign: 'center', marginTop: '100px'}}>
                        <h2 className='styled-header'>Boat Type Split</h2>
                        <VictoryPie
                            colorScale={['#375e9e', '#578feb', '#1e6ae6' ]}
                            data={boatTypeData}
                            style={{
                                axisLabel: {fontSize: 15, padding: 30, fill: "white"},
                                tickLabels: {fontSize: 10, padding: 5, fill: "white"},
                                labels: {fontSize: 15, padding: 15, fill: "white"},
                            }}
                        />
                    </div>
                </Col>

                <Col md={6} lg={4}>
                    <div style={{height: '300px', textAlign: 'center', marginTop: '100px'}}>
                        <h2 className='styled-header'>Powerboat Hull Type Split</h2>
                        <VictoryPie
                            colorScale={['#578feb', '#375e9e', '#1e6ae6' ]}
                            data={powerHullTypeData}
                            style={{
                                axisLabel: {fontSize: 15, padding: 30, fill: "white"},
                                tickLabels: {fontSize: 10, padding: 5, fill: "white"},
                                labels: {fontSize: 15, padding: 15, fill: "white"},
                            }}
                        />
                    </div>
                </Col>
                <Col md={6} lg={4}>
                    <div style={{height: '300px', textAlign: 'center', marginTop: '100px'}}>
                        <h2 className='styled-header'>Yacht Hull Type Split</h2>
                        <VictoryPie
                            colorScale={['#375e9e', '#578feb', '#1e6ae6' ]}
                            data={yachtHullTypeData}
                            style={{
                                axisLabel: {fontSize: 15, padding: 30, fill: "white"},
                                tickLabels: {fontSize: 10, padding: 5, fill: "white"},
                                labels: {fontSize: 15, padding: 15, fill: "white"},
                            }}
                        />
                    </div>
                </Col>

            </Row>

        </Container>
    )
}
