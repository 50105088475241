import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import Image from 'react-bootstrap/Image'

export default function Header(props) {

    const [isOpen, setIsOpen] = React.useState(false)

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

  return (
    <div style={{height: '10vh', backgroundColor: 'rgba(rgba(36, 39, 49, 0.6)'}}>
        <Container>
            <Row>
                <Col>
                    <img style={{height: '8vh', marginTop: '1vh'}} alt="logo" src="./new_logo_trans.png"></img>
                </Col>
                <Col>
                <div style={{paddingTop: '3vh', paddingRight: '4vw'}}>
                    <i onClick={() => {toggleDrawer()}} style={{float: 'right', color: 'white'}} class="fa fa-2x fa-bars"></i>
                </div>
                </Col>
            </Row>
        </Container>
        <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                style={{backgroundColor: 'rgba(36, 39, 49, 1)'}}
            >
            <div style={{marginLeft: '30px'}}>
                <div style={{textAlign: 'center', marginTop: '40px'}}>
                    <Image style={{width: '80%'}} src='./new_logo_trans.png' />
                </div>
                <div>
                <Row style={{marginTop:'2vw'}}>
                    
                    <p style={{fontSize: '4vw', marginTop:' 20px'}} className='top-nav-link' href='#' onClick={() => {props.setCurrentPage('SearchPage'); toggleDrawer()}}>Search</p>
                    
                    <p style={{fontSize: '4vw', marginTop:' 20px'}} className='top-nav-link' href='#' onClick={() => {props.setCurrentPage('Insights'); toggleDrawer()}}>Market Insights</p>

                    <a style={{fontSize: '4vw', marginTop:' 20px'}} className='top-nav-link' href='https://wefindyourboat.com'>Get In Touch</a>
                
                </Row>
                </div>
            </div>
        </Drawer>
    </div>
  )
}
