import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { VictoryChart, VictoryArea, VictoryAxis } from 'victory'

export default function Insights(props) {

  return (
    <div>
        <Container fluid>
            <Row>
                <Col>
                    { props.heatmapSrc ?
                        <div style={{textAlign: 'center', marginTop: '50px'}}>
                            <h2 className='styled-header' style={{marginBottom: '30px'}}>Similar Boats Location Distribution</h2>
                            <Image src={props.heatmapSrc} style={{width: '100%'}} />
                        </div>
                        :
                        null
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    { props.lengthData.length > 0 ? 
                        <div style={{textAlign: 'center', marginTop: '50px'}}>
                            <h2 className='styled-header' style={{marginBottom: '30px'}}>Similar Boats Length Distribution</h2>
                            <VictoryChart>
                                <VictoryAxis style={{
                                    axisLabel: {fontSize: 15, padding: 30, fill: "white"},
                                    tickLabels: {fontSize: 10, padding: 5, fill: "white"},
                                    labels: {fontSize: 5, padding: 5, fill: "white"},
                                }}
                                fixLabelOverlap={true}
                                label="Length (m)"
                                >

                                </VictoryAxis>
                                <VictoryArea
                                    interpolation="cardinal"
                                    style={{
                                        data: { stroke: "rgb(1, 151, 221)", fill: "rgb(1, 151, 221)" },
                                        parent: { border: "1px solid white"},
                                        labels: {fill: 'white'},
                                    }}
                                    data={props.lengthData}
                                />
                            </VictoryChart>
                        </div>
                        :
                        null
                    }
                </Col>
                <Col xs={12} md={6}>
                    { props.priceData.length > 0 ? 
                        <div style={{textAlign: 'center', marginTop: '50px'}}>
                            <h2 className='styled-header' style={{marginBottom: '30px'}}>Similar Boats Price Distribution</h2>
                            <VictoryChart>
                                <VictoryAxis style={{
                                    axisLabel: {fontSize: 15, padding: 30, fill: "white"},
                                    tickLabels: {fontSize: 10, padding: 10, fill: "white", angle: -25},
                                    labels: {fontSize: 5, padding: 5, fill: "white"},
                                }}
                                fixLabelOverlap={true}
                                label="Price (USD)"
                                >

                                </VictoryAxis>
                                <VictoryArea
                                    interpolation="cardinal"
                                    style={{
                                        data: { stroke: "rgb(1, 151, 221)", fill: "rgb(1, 151, 221)" },
                                        parent: { border: "1px solid white"},
                                        labels: {fill: 'white'},
                                    }}
                                    data={props.priceData}
                                />
                            </VictoryChart>
                        </div>
                        :
                        null
                    }
                </Col>
            </Row>
        </Container>
    </div>
  )
}
